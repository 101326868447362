<template>
  <div class="well">
    <div class="well-title mb-1">{{ipndData.label}}</div>
    <p class="title text--primary">
      {{ipndData.address_string}}
    </p>
<!--    <v-row dense>-->
<!--      <v-col><span class="no-wrap">Customer Name:</span> </v-col>-->
<!--      <v-col><span class="no-wrap">{{ipndData.customer_name_1}} {{ipndData.customer_name_2}}</span></v-col>-->
<!--    </v-row>-->
    <v-row dense v-if="ipndData.alternate_address_flag == 'T'">
      <v-col><span class="no-wrap">Customer Contact Name:</span> </v-col>
      <v-col><span class="no-wrap">{{ipndData.customer_contact_name_1}} {{ipndData.customer_contact_name_2}}</span></v-col>
    </v-row>
    <v-row dense v-if="ipndData.alternate_address_flag == 'T'">
      <v-col><span class="no-wrap">Customer Contact Number:</span> </v-col>
      <v-col>{{ipndData.customer_contact_nr}}</v-col>
    </v-row>
    <v-row dense>
      <v-col><span class="no-wrap">Using Alternate Address:</span> </v-col>
      <v-col><span v-if="ipndData.alternate_address_flag == 'T'">Yes</span><span v-if="ipndData.alternate_address_flag == 'F'">No</span></v-col>
    </v-row>
    <v-row dense>
      <v-col><span class="no-wrap">Usage Type:</span> </v-col>
      <v-col><span class="no-wrap">{{ipndData.usage_string}}</span></v-col>
    </v-row>
  </div>
</template>

<script>
export default {
    name: 'IPNDDetailsDisplayComponent',

    props: {
      ipndData: {
        type: Object,
        default: null,
      },
  },
    data: () => ({
    }),
};
</script>
<style scoped>
.well{
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  .well-title{
    font-size:larger;
  }
}

.no-wrap {
  white-space: nowrap;
}
</style>
