<template>
  <ComplexDialog
    v-model="visible"
    title="Update Trunk/Account Address"
    :loading="busy"
    :maxWidth="1000"
    noText="Cancel"
    yesText="Update"
    @click="changeAddress( )"
  >
    <ExistingAddressDetailsForm
      v-model="address"
      v-if="visible"
      show-manual-entry
      :customerId="customerId"
      :addresses="storedAddresses"
    />
    <template v-slot:actions>
      <v-spacer/>
      <v-btn @click="visible=false" text>Cancel</v-btn>
      <v-btn @click="deleteConfirm=true" :disabled="(address == null)" text>Delete</v-btn>
      <v-btn @click="changeAddress( )" :disabled="(address == null)" text color="primary">Continue</v-btn>
    </template>
    <SimpleDialog
      v-model="deleteConfirm"
      title="Delete Address Record?"
      @click="deleteAddress"
      :loading="busy"
      yesText="Yes, delete"
      noText="No, cancel"
      yesColor="red"
      styledHeader
    >Are you sure you wish to delete address: <AddressText :data="address" />?
    </SimpleDialog>
  </ComplexDialog>
</template>
<script>
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
import ExistingAddressDetailsForm from "../../pieces/Forms/ExistingAddressDetailsForm";
import ComplexDialog from "../templates/ComplexDialog";
import AddressText from '../../pieces/Address/AddressText';
import SimpleDialog from "../templates/SimpleDialog";
export default {
  name: 'UpdateAddressDialog',
  components: {SimpleDialog, ComplexDialog,ExistingAddressDetailsForm,AddressText},
  mixins: [apiMixin, dialogMixin],
  data: () => ({
    busy: false,
    address: null,
    deleteConfirm: false,
    storedAddresses:[],
    selectedAddress:null, //different to address as this could be records that alread exists
  }),
  props: {
    customerId: {
      type: Number,
      default: null
    },
    trunkId: {
      type: Number,
      default: null
    },
    /*
      customer: Update the customer address
      trunk: Update the trunk. This is now obsolete
      customer-store: Uses customer addresses. Will save and return a select addressed.
     */
    typeSwitch:{
      type: String,
      default: 'customer',
    },
    /**
     * Rather than run the internal logic to change the address,
     * if set to try this will emit a addressSelected event.
     * Allow the parent to handle the results differently.
     *
     * This was introducted for cater for updating IPND address.
     * Were we need to submit the address to NetSip for IPND validation
     * before we can change any address records
     *
     * Note that an new address might need to be created which will need to be handled
     * by the parent
     */
    emitOnChange:{
      type:Boolean,
      default:false,
    }
  },
  methods: {
    changeAddress( ){
      if(this.emitOnChange){
        this.$emit('addressSelected', this.address);
      }else{
        this.changeAddressUpdate();
      }
    },

    async changeAddressUpdate() {
      this.busy = true;
      let newAddressId = null;
      let deleteOnFail = false;
      this.selectedAddress = null;
      if(!this.address.address_id && this.checkUnique(this.address)){
        //new address is entered and it does not already exist. So we add it.
        const responseNewAddress = await this.Api.send('post','customers/' + this.customerId + '/addresses',this.address, false);
        if (responseNewAddress.success) {
          newAddressId = responseNewAddress.data.id;
          this.selectedAddress = responseNewAddress.data;
          deleteOnFail = true;
          await this.getAddresses(true);
        }else{
          this.busy = false;
          return;
        }
      }else if(!this.address.address_id) {
        this.selectedAddress = this.getMatchingAddress(this.address);
        this.selectedAddress.id = this.selectedAddress.address_id;
        delete this.selectedAddress.address_id;
        newAddressId = this.selectedAddress.address_id
      }else{
        this.selectedAddress = this.address;
        this.selectedAddress.id = this.selectedAddress.address_id;
        delete this.selectedAddress.address_id;
        newAddressId = this.address.address_id;
      }

      let responseChangeAddress = null;
      if(this.typeSwitch == 'customer') {
        responseChangeAddress = await this.Api.send('put', 'customers/' + this.customerId + '/addresses/' + newAddressId + '/setActive', {"deleteAddressOnFail":deleteOnFail}, false);
      }else if(this.typeSwitch == 'trunk') {
        this.Api.setHttpObject({timeout:60000});
        /**
         * Note that we do not delete on fail for a trunk.
         * This is because when an address is changed on a trunk and the trunk has a DID that is linked to a NetSip Indial
         * we need to update the IPND (address record) linked to the indial.
         * This will sometimes fail to save the address to NetSip as netsip is strict in its address matching.
         * In such situations the address is SUCCESSFULLY APPLIED to the trunk via PortaOne but fails to update in netsip.
         * Hence with this type of failure we still want to address in the database as the trunk and PortaOne are updated.
         * Ideally this all should be handled on the API. Rather than the deleted on fail fired form the front end.
         *
         * However do not have time for this atm and not convinced the feature is worth the engineering.
         * So any failure to add an address to a PortaAccount the address will still exist in the users list.
         *
         *
         * UPDATE: 20240529
         * I'm not convinced that this is correct with the new updates.
         * The process should now cater for the logic as we would never accept the address if it was not update for an IPND.
         * Need to review this logic here later and make adjustments.
         */
        deleteOnFail = false;
        responseChangeAddress = await this.Api.send('put', 'trunks/' + this.trunkId + '/address/' + newAddressId,  {"deleteAddressOnFail":deleteOnFail}, false);
        this.Api.setHttpObject({timeout:20000});
      }else if(this.typeSwitch == 'customer-store'){
        //we do nothing. Address is already stored
        responseChangeAddress = {"success":true};
      }else{
        throw Error('Unrecognised typeSwitch ('+this.typeSwitch+').');
      }
      if (!responseChangeAddress.success && deleteOnFail) {
        //will fail silently.
        //delete now happens via API
        // const responseDeleteAddress = await this.Api.send('delete','customers/' + this.customerId + '/addresses/'+newAddressId, {}, true);
        await this.getAddresses(true);
      }

      this.address = null
      this.busy = false;
      this.visible = false;

      //document.dispatchEvent(new CustomEvent('refreshRequested'));
      this.$emit('address-selected', this.selectedAddress);

    },

    /**
     *
     * @param ignoreBusyToggle does not toggle the busy toggle.
     * This method is used within the changeAddress process and
     * this can toggle off the busy toggle mid process.
     * @returns {Promise<void>}
     */
    async getAddresses(ignoreBusyToggle = false) {
      if(!ignoreBusyToggle) {
        this.busy = true;
      }
      const response = await this.Api.send('get','customers/' + this.customerId + '/addresses');
      if (response.success) {
        this.storedAddresses = response.data;
      }
      if(!ignoreBusyToggle) {
        this.busy = false;
      }
    },

    checkUnique(checkAddress)
    {
      let matched = this.getMatchingAddress(checkAddress);
      if(matched == null){
        return true;
      }else{
        return false;
      }
    },

    getMatchingAddress(matchAddress)
    {
      //clean
      for (let prop in matchAddress) {
        if(matchAddress[prop] == "" || matchAddress[prop] == null){
          matchAddress[prop] = null;
        }
      }

      let mismatch = false;
      for(let i = 0; i<this.storedAddresses.length; i++) {
        mismatch = false;
        for (let prop in matchAddress) {
          if(matchAddress[prop] != this.storedAddresses[i][prop]){
            mismatch = true;
            break;
          }
        }
        if(mismatch == false){
          return this.storedAddresses[i];
        }
      }
      return null;
    },

    async checkIfCurrent(checkAddress)
    {
      const response = await this.Api.send('post','customers/' + this.customerId + '/addresses/checkIfCurrent', checkAddress, false);
      return response.data.checkIfCurrent;
    },

    async deleteAddress() {
      this.busy = true;
      if(this.address.address_id){
        /* NOTE this was added to prevent users delete the address record linked to a customer.
        but address can be link in multiple places so this has been disabled for now.

        if(await this.checkIfCurrent(this.address)){
          this.Api.throwError('Cannot delete user\'s currently save address.');
          this.busy = false;
          this.deleteConfirm = false;
          return;
        }*/
        const responseDeleteAddress = await this.Api.send('delete','customers/' + this.customerId + '/addresses/'+this.address.address_id, {}, false);
      }
      this.address = null;
      await this.getAddresses();
      this.busy = false;
      this.deleteConfirm = false;
      this.visible = false;
    },
  },
  watch: {
    customerId(value) {
      if(value != null){
        this.getAddresses();
      }
    }
  },
};
</script>